import { BrowserRouter, Routes, Route } from "react-router-dom";
import Chat from  './pages/Chat'
import Hire from './pages/Hire'

function App() {
  return(
    <div>
      <BrowserRouter>
        <Routes>
        <Route index element={<Chat />} />
        <Route path="/hire" element={<Hire />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
 }

export default App;
