// Hire.js
import "./Hire.css";
import gptLogo from "../assets/hour0.png";
import agents from "../allAgents.json";
import { useState } from "react";
import AgentCard from "./AgentCard";

function Hire() {
  const [filter, setFilter] = useState("");
  const [filteredAgents, setFilteredAgents] = useState(agents);
  const [agent, setAgent] = useState(agents[0]);
  const [agInx, setAgInx] = useState(0);

  const handleFilterChange = (event) => {
    const value = event.target.value.toLowerCase();
    setFilter(value);
    const filtered = agents.filter((agent) =>
      agent.name.toLowerCase().includes(value) ||
      agent.title.toLowerCase().includes(value) ||
      agent.tags.toLowerCase().includes(value) ||
      agent.welcome.toLowerCase().includes(value)
    );
    setFilteredAgents(filtered);
  };

  const handleAgentSelected = (index) => {
    let selected = agents[index];
    setAgent(selected);
  }

  return (
    <div className="App">
      <div className="main">
        <img src={gptLogo} alt="Logo" className="logo" />
        <div className="search-bar">
        <input
          type="text"
          value={filter}
          onChange={handleFilterChange}
          placeholder="Search by name, title, tags"
          className="search-box"
        />
        <button className="search-button" >SEARCH</button>
        </div>
        <div className="agent-box">
          {filteredAgents.map((agent, index) => (
            <div className="agent-holder" onClick={() => handleAgentSelected(index)}>
                <AgentCard key={agent.id} agent={agent} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Hire;
