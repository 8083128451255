const { Configuration, OpenAIApi } = require("openai");
const apiKey = "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";
const configuration = new Configuration({ apiKey });
const openai = new OpenAIApi(configuration);

export async function sendMsgToOpenAI(agent, message) {
  try {
    const response = await openai.createChatCompletion({
      model: "gpt-4o", // Use GPT-4 model
      messages: [ { role: "system", content: agent } , { role: "user", content: message }],
      temperature: 0.7,
      max_tokens: 1000,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error("Error communicating with OpenAI:", error);
    throw error;
  }
}
