// AgentCard.js
import React from 'react';
import './AgentCard.css';

const AgentCard = ({ agent }) => {

  const calcExperience = (e) => {
    if(e >= 12)
      return `${Math.floor(e / 12)}+ years`;
    return `${e} months`;
  };

  return (
    <div className="agent-card">
      <div className="agent-header">
        <div className="agent-name">
          <h1>{agent.name}</h1>
          <div className="agent-title">{agent.title} ({agent.level})</div>
        </div>
      </div>
      <div className="agent-body">
        <div className="agent-experience">{calcExperience(agent.experience)}</div>
        <div className="agent-rating">
          {'★'.repeat(Math.floor(agent.rank))}{'☆'.repeat(Math.ceil(5 - agent.rank))}
          <span className='agent-reviews'>{agent.rank} ({agent.reviews} reviews)</span>
        </div>
          <img className="agent-image" src={`/assets/ag/${agent.id}.jpg`} alt={agent.name}/>
        <div className="agent-tags">
          {agent.tags.split(',').map((tag, index) => (
            <span key={index} className="agent-tag">{tag}</span>
          ))}
        </div>
        <div className="agent-salary">${agent.salary} / Month</div>
      </div>
    </div>
  );
};

export default AgentCard;
