import "./Chat.css";
import gptLogo from "../assets/hour0.png";
import msgIcon from "../assets/message.svg";
import sendBtn from "../assets/send.svg";
import userIcon from "../assets/user-icon2.png";
import agents from "../agents.json";
import { useEffect, useRef, useState } from "react";
import { sendMsgToOpenAI } from "../openai";
import ReactMarkdown from 'react-markdown';
import { useNavigate } from "react-router-dom";

function Chat() {
  const [agent, setAgent] = useState(agents[0]);
  const [agInx, setAgInx] = useState(0);
  const navigate = useNavigate();

  const msgEnd = useRef(null);

  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([
    {
      text: agent.welcome,
      isBot: true,
    },
  ]);

  const handleAgentSelected = (index) => {
    setAgInx(index);
      let selected = agents[index];
      setAgent(selected);
      setMessages([
        {
          text: selected.welcome,
          isBot: true,
        },
      ])
  }

  useEffect(() => {
    msgEnd.current.scrollIntoView();
  }, [messages]);

  const handleSend = async () => {
    const text = input;
    setInput("");
    setMessages([...messages, { text, isBot: false }]);
    const res = await sendMsgToOpenAI(agent.welcome, text);
    setMessages([
      ...messages,
      { text, isBot: false },
      { text: res, isBot: true },
    ]);
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter") await handleSend();
  };

  const handleHire = async (e) => {
    navigate("/hire");
  };

  const handleQuery = async (e) => {
    const text = e.target.value;
    setMessages([...messages, { text, isBot: false }]);
    const res = await sendMsgToOpenAI(agent.welcome, text);
    setMessages([
      ...messages,
      { text, isBot: false },
      { text: res, isBot: true },
    ]);
  };

  return (
    <div className="App">
      <div className="sideBar">
        <div className="upperSide">
          <div className="upperSideTop">
            <img src={gptLogo} alt="Logo" className="logo" />
          </div>
          {agents.map((agent, index) => (
            <button  className={"agentBtn " + (agInx === index ? "selected" : "")} key={index} onClick={() => handleAgentSelected(index)}>
              <img src={`/assets/ag/${agent.id}.jpg`} alt={agent.name}/>
              <div>
                <h2>{agent.name}</h2>
                <h5>{agent.title}</h5>
              </div>
            </button>
          ))}
          <div className="upperSideBottom">
            <button className="midBtn" onClick={handleHire}>Hire a new assistant</button> 
          </div>
        </div>
        <div className="lowerSide">
          <button
              className="query"
              onClick={handleQuery}
              value={"How Can you help me ?"}
            >
              <img src={msgIcon} alt="Query" />
              How Can you help me ?
            </button>
            <button
              className="query"
              onClick={handleQuery}
              value={"Give me a piece of advice"}
            >
              <img src={msgIcon} alt="Query" />
              Give me a piece of advice
            </button>
        </div>
      </div>
      <div className="main">
        <div className="chats">
          {messages.map((message, i) => (
            <div key={i} className={message.isBot ? "chat bot" : "chat"}>
              <img
                className="chatImg"
                src={message.isBot ? `/assets/ag/${agent.id}.jpg` : userIcon}
                alt="ChatGPT"
              />
              <div className="txt"><ReactMarkdown>{message.text}</ReactMarkdown></div>
            </div>
          ))}
          <div ref={msgEnd} />
        </div>
        <div className="chatFooter">
          <div className="inp">
            <input
              type="text"
              placeholder="Send a message"
              value={input}
              onKeyDown={handleEnter}
              onChange={(e) => {
                setInput(e.target.value);
              }}
            />
            <button className="send" onClick={handleSend}>
              <img src={sendBtn} alt="Send" />
            </button>
          </div>
          <p>
            * it's just a very first version for demo and test propuses *
          </p>
        </div>
      </div>
    </div>
  );
}

export default Chat;
